<template>
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 17.0467C9 16.4929 8.54972 16.0515 8.00297 15.9733C4.60923 15.488 2 12.5619 2 9.02472C2 5.14811 5.13401 2.00549 9 2.00549C12.866 2.00549 16 5.14811 16 9.02472C16 9.89001 15.8439 10.7187 15.5583 11.4841C15.2993 12.1782 15.7588 13.0357 16.4979 13.0357C16.845 13.0357 17.1659 12.8401 17.3004 12.5193C17.751 11.4444 18 10.2637 18 9.02472C18 4.04051 13.9706 0 9 0C4.02944 0 0 4.04051 0 9.02472C0 13.6706 3.50088 17.4965 8.0016 17.9945C8.55055 18.0553 9 17.6005 9 17.0467Z"
      fill="currentColor"
    />
    <path
      d="M9 4.01099C9.55229 4.01099 10 4.45993 10 5.01374V8.52335H13C13.5523 8.52335 14 8.9723 14 9.5261C14 10.0799 13.5523 10.5288 13 10.5288H9C8.44771 10.5288 8 10.0799 8 9.5261V5.01374C8 4.45993 8.44771 4.01099 9 4.01099Z"
      fill="currentColor"
    />
    <path
      d="M12 15.0347C11.4477 15.0347 11 15.4837 11 16.0375C11 16.5913 11.4477 17.0402 12 17.0402H15.9999C16.5522 17.0402 16.9999 16.5913 16.9999 16.0375C16.9999 15.4837 16.5522 15.0347 15.9999 15.0347H12Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { Vue } from "vue-class-component";

export default class Timer extends Vue {}
</script>
